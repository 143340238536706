var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.info != null && _vm.info.email
    ? _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.info.authentication_method != "donotsend",
              expression: "info.authentication_method != 'donotsend'",
            },
          ],
          key: _vm.loa_level,
          class: { "": _vm.notSending },
        },
        [
          _c("div", { key: _vm.options.length, staticClass: "d-flex" }, [
            _c("div", { staticClass: "flex-grow-1" }, [
              _c("div", { staticClass: "form-control-plaintext" }, [
                _c("i", {
                  staticClass: "fal fa-trash-alt clickable mr-2",
                  on: { click: _vm.RemoveBtn },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    attrs: { title: _vm.GetName },
                  },
                  [
                    _vm.showSefos
                      ? _c("img", {
                          staticClass: "icon mr-1",
                          attrs: { src: "/img/message_type/message.png" },
                        })
                      : _vm._e(),
                    _vm._v(_vm._s(_vm.GetPartialName)),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              [
                _vm.info.authentication_method == "conversation" ||
                _vm.info.configured
                  ? _c(
                      "b-form",
                      { staticClass: "mb-2 w-350", attrs: { inline: "" } },
                      [
                        _c("b-form-input", {
                          staticClass: "w-350",
                          attrs: {
                            trim: "",
                            plaintext: "",
                            value: _vm.$t("CONVERSATION_CONFIGURED"),
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.info.authentication_method != "conversation" &&
                !_vm.info.configured
                  ? _c(
                      "b-form",
                      { staticClass: "mb-2", attrs: { inline: "" } },
                      [
                        _c(
                          "b-form-select",
                          {
                            staticClass: "choose-lang",
                            attrs: { name: "not_registered_select_language" },
                            model: {
                              value: _vm.info.language,
                              callback: function ($$v) {
                                _vm.$set(_vm.info, "language", $$v)
                              },
                              expression: "info.language",
                            },
                          },
                          _vm._l(_vm.sefos_locales, function (value, key) {
                            return _c(
                              "b-form-select-option",
                              { key: key, attrs: { value: key } },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("LANGUAGE." + key)) +
                                    "\n          "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                        _c("b-form-select", {
                          staticClass: "w-250 no-mobile-padding-left",
                          attrs: {
                            options: _vm.options,
                            state: _vm.info.validated,
                          },
                          model: {
                            value: _vm.info.authentication_method,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "authentication_method", $$v)
                            },
                            expression: "info.authentication_method",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "mobile-block" },
                          [
                            _vm.info.authentication_method == "email-otp"
                              ? _c("EmailOTP", {
                                  attrs: { required: "" },
                                  on: { valid: _vm.setValidated },
                                  model: {
                                    value: _vm.info.authentication_identifier,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.info,
                                        "authentication_identifier",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "info.authentication_identifier",
                                  },
                                })
                              : _vm._e(),
                            _vm.info.authentication_method == "sms"
                              ? _c("Phone", {
                                  attrs: { required: "" },
                                  on: { valid: _vm.setValidated },
                                  model: {
                                    value: _vm.info.authentication_identifier,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.info,
                                        "authentication_identifier",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "info.authentication_identifier",
                                  },
                                })
                              : _vm._e(),
                            _vm.hideIdentifier &&
                            _vm.info.authentication_method == "se-eid"
                              ? _c("b-form-input", {
                                  attrs: {
                                    trim: "",
                                    type: "password",
                                    value: "HAS_VALUE",
                                    state: true,
                                  },
                                  on: { click: _vm.showIdentifier },
                                })
                              : _vm._e(),
                            !_vm.hideIdentifier &&
                            _vm.info.authentication_method == "se-eid"
                              ? _c("Pnr", {
                                  attrs: { required: "" },
                                  on: {
                                    valid: _vm.setValidated,
                                    blur: _vm.blurPnr,
                                  },
                                  model: {
                                    value: _vm.info.authentication_identifier,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.info,
                                        "authentication_identifier",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "info.authentication_identifier",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }